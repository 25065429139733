<template>
	<loginBase class="" 
    element-loading-text="登录中,请稍后..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.2)">
		<div  v-if="page!='qr'">
			<!-- <div @click="pageChangeFun('qr')" class="imgC"></div>
			<div class="imgT"></div> -->
			<div class="title">
				<!-- <span @click="tabChange('code')" :class="type=='code'?'title_active':''">验证码登录</span>
				<strong></strong> -->
				<span class="title_active">密码登录</span>
			</div>
			
			<!-- <el-form v-if="type=='code'" class="loginForm" :model="codePost" :hide-required-asterisk="true" :rules="codeRules" ref="codeForm" label-width="80px" label-position="top">
			  <el-form-item class="phoneNumer" label="手机号码" prop="phone">
					<el-input  placeholder="请输入手机号码" v-model="codePost.phone">
					<template slot="prefix">
						<el-dropdown  @command="phoneNumFun" trigger="click">
							  <span class="phoneNumer_line" style="cursor: pointer;">
								<span style="width: 30px;display: inline-block; ">{{phoneNum}}</span> <i class="el-icon-caret-bottom"></i>
							  </span>
							  <el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="item.val" v-for="(item,index) in phoneNumList">{{item.name}} {{item.val}}</el-dropdown-item>
							  </el-dropdown-menu>
							</el-dropdown>
					</template>
					</el-input>
			  </el-form-item>
			  <el-form-item class="getcode" label="验证码" prop="code">
				<el-input  v-model="codePost.code"  placeholder="请输入验证码">
					<template slot="suffix">
						<span @click="getCodeFun" class="getcode_num" :style="{'color':(codePost.phone.length==11)?'#006aff':'#b6babf'}">{{codetitle}}</span>
					</template>
				</el-input>
			  </el-form-item>
			  <el-form-item label="" style="margin-top: -10px;">
				<div class="page_to">
					<router-link to="adminregister">免费注册</router-link>
				</div> 
			  </el-form-item>
			  <el-form-item style="position: absolute;bottom: 15px;width: 340px;">
				<el-button type="primary" style="width: 100%;height: 50px;">登录</el-button>
				<p class="agrement">登录即表示同意<a href="">《注册协议》</a><a href="">《个人信息处理规则》</a></p>
			  </el-form-item>
			</el-form> -->
			
			<el-form class="loginForm" :model="pwdPost" :hide-required-asterisk="true" :rules="pwdRules" ref="pwdForm" label-width="80px" label-position="top">
			  <el-form-item label="登录账号" prop="username">
				<el-input @keyup.enter.native="loginFun" v-model="pwdPost.username" placeholder="请输入登录账号"></el-input>
			  </el-form-item>
			  <el-form-item label="登录密码" prop="password">
				<el-input @keyup.enter.native="loginFun" type="password" v-model="pwdPost.password"  placeholder="请输入密码"></el-input>
			  </el-form-item>
			 <!-- <el-form-item label="" style="margin-top: -10px;">
				<div class="page_to">
					<router-link to="adminregister">免费注册</router-link>
					<router-link to="adminforget">忘记密码</router-link>
				</div> 
			  </el-form-item> -->
			  <el-form-item style="position: absolute;bottom: 15px;width: 340px;">
				<el-button @keyup.enter.native="loginFun" @click="loginFun" type="primary" style="width: 100%;height: 50px;font-size: 16px;">{{!loginLoading?'登录':'登录中，请稍等'}}</el-button>
				<p class="agrement">登录即表示同意<a href="">《注册协议》</a><a href="">《个人信息处理规则》</a></p>
			  </el-form-item>
			</el-form>
		</div>
		
		<!-- <div v-else>
			<div @click="pageChangeFun('input')" class="imgCQ"></div>
			<div class="imgTQ"></div>
			<div class="title">
				<span class="title_active">微信扫码登录</span>
			</div>
			<div class="qr_box">
				<div class="qr_box_img"></div>
				<div class="qr_box_flex">
					<img src="./login/img/wxicon.png" alt="" srcset="" />
					微信扫一扫
				</div>
			</div>
			<p class="agrement" style="margin-top: 35px;">登录即表示同意<a href="">《注册协议》</a><a href="">《个人信息处理规则》</a></p>
		</div> -->
	</loginBase>
</template>

<script>
	import { eltips } from "../../util/util";
	import {
	  resetRouter
	} from '@/router/index.js'
	import {
	  adminroutes
	} from '../../router/admin.js'
	import store from '@/store/index.js'
	import router from '@/router/index.js'
	import {
	  changeroute
	} from '@/router/routerPermission.js'
	import loginBase from './login/components/base.vue'
	export default {
		components:{loginBase},
		name: 'adminlogin',
		data() {
			return {
				page:'input',
				type:'code',
				phoneNum:'+86',
				phoneNumList:[
					{name:'中国大陆',val:'+86'},
					{name:'中国香港',val:'+852'}
				],
				
				codeInter:null,
				codeTime:60,
				codetitle:'获取验证码',
				codePost:{
					phone:'',
					code:'',
				},
				codeRules: {
					phone: [
						{ required: true, message: '请输入手机号码', trigger: 'blur' },
					],
					code: [
						{ required: true, message: '请输入验证码', trigger: 'blur' },
					]
				},
				
				pwdPost:{
					username:'',
					password:'',
				},
				pwdRules: {
					username: [
						{ required: true, message: '请输入手机号码', trigger: 'blur' },
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' },
					]
				},
				loginLoading:false
			}
		},
		create(){
		},
		methods:{
			loginFun() {
				let _this = this
				_this.loginLoading = true
			  this.axios.post("/api/manage/basic/login", {
			    username: this.pwdPost.username,
			    password: this.pwdPost.password
			  }).then((res) => {
			    if (res.code == 200) {
			      eltips('登录成功', 'success')
			      sessionStorage.setItem('token', res.data.token)
			      sessionStorage.setItem('loginPage', '/adminlogin')
			      sessionStorage.setItem('guardName', 'admin_user')
				  console.log("ddd",sessionStorage.getItem('guardName'))
			      this.xinxi(res.data.menu_list)
			      // this.getConfig()
			    } else {
			      eltips(res.msg, 'error')
			    }
				setTimeout(function(){
					_this.loginLoading = false
				},1000)
			  })
			},
			xinxi(menu) {
					  let _this = this
			  this.axios.get("/api/manage/basic/user-info", {
			    params: {
			      // guard_name: 'api'
			    }
			  }).then((res) => {
			    if (res.code == 200) {
			      // return false
			      sessionStorage.setItem('userinfo', JSON.stringify(res.data))
			      resetRouter()
					this.$store.commit('routestatus', 0)
					sessionStorage.removeItem('routestext')
					sessionStorage.removeItem('routes')
					sessionStorage.setItem('routes', JSON.stringify([...adminroutes,..._this.traverseTree(menu)]))
					_this.$router.replace({
					  path: '/admin/index'
					});
			      
			    } else {
			      eltips(res.msg, 'error')
			    }
			  })
			},
			traverseTree (treeList) {
					  let _this = this
					const keyMap = {
					    title: 'name', // 将 "id" 修改为 "nodeId"
					    uri: 'component', // 将 "name" 修改为 "label"
					    url: 'path', // 将 "name" 修改为 "label"
					    // children: '_child', // 将 "name" 修改为 "label"
					    icon_url: 'icon', // 将 "name" 修改为 "label"
					    layout: 'layout', // 将 "name" 修改为 "label"
					    router_name: 'router_name', // 将 "name" 修改为 "label"
					    vue_name: 'vue_name', // 将 "name" 修改为 "label"
					    app_level: 'app_level', // 将 "name" 修改为 "label"
						
					};
			  return treeList.map((item) => ({
			    ...Object.keys(keyMap).reduce((acc, oldKey) => {
			      const newKey = keyMap[oldKey];
			      acc[newKey] = item[oldKey];
			      delete item[oldKey];
			      return acc;
			    }, {}),
			    _child: item.children ? _this.traverseTree(item.children) : [],
			  }));
			},
			pageChangeFun(e){
				this.page = e
			},
			getCodeFun(){
				let _this = this
				if( _this.codetitle != '获取验证码'){
					return
				}
				this.codeInter = setInterval(function(){
					_this.codeTime--;
					_this.codetitle = _this.codeTime + 'S'
					if(_this.codeTime<=1){
						clearInterval(this.codeInter)
						this.codeInter = null
						_this.codetitle = '获取验证码'
					}
				},1000)
			},
			tabChange(e){
				this.type = e
				// this.$nextTick(() => {
				// 	this.$refs.codeForm.clearValidate();
				// 	this.$refs.pwdForm.clearValidate();
				// })
			},
			phoneNumFun(e){
				this.phoneNum = e
				console.log(e)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.imgC{
		position: absolute;
		top: 0;
		right: 0;
		width: 101px;
		height: 101px;
		display: block;
		background: url(./login/img/qrcode2.png) no-repeat 50%;
		background-size: 90px;
		cursor: pointer;
		z-index: 9;
	}
	.imgC:hover{
		width: 101px;
		height: 101px;
		background-image: url(./login/img/qrcode_hover2.png)!important;
		transition: all .4s ease;
		background-size: 90px;
	}
	.imgT{
		position: absolute;
		right: 0;
		top: 0;
		width: 90px;
		height: 90px;
		border-radius: 0 10px 0 0;
		border-color: #f2f7ff #f2f7ff #fff #fff;
		border-style: solid;
		border-width: 50px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}
	.title{
		margin-top: 12px;
		span{
			font-size: 20px;
			color: #b6babf;
			cursor: pointer;
			font-weight: 400;
			-webkit-transition: color .2s linear;
			transition: color .2s linear;
		}
		&_active{
			color: #1e2226 !important;
			font-weight: 600 !important;
		}
		strong{
			width: 1px;
			height: 16px;
			background: #e1e2e6;
			margin: 0 12px;
			display: inline-block;
		}
	}
	.loginForm{
		margin-top: 30px;
	}
	.loginForm ::v-deep .el-form-item__label{
		padding-bottom: 0;
		font-weight: 600;
		color: #1f2026;
		font-size: 14px;
	}
	.page_to{
		display: flex;
		align-items: center;
		justify-content: space-between;
		a{
			color: #8a9099;
		}
	}
	.agrement{
		display: flex;
		color: #8a9099;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		margin-top: 10px;
		height: 19px;
		line-height: 20px;
		font-size: 12px;
		a{
			color: #1e2226;
		}
	}
	
	.phoneNumer{
		&_line{
			height: 40px;
			line-height: 40px;
			border-right: 1px solid #ededed;
			padding-right: 8px;
			padding-left: 10px;
		}
	}
	
	.phoneNumer ::v-deep .el-input-group__prepend{
		background-color: #fff;
		border-right: 0 !important;
	}
	.phoneNumer ::v-deep .el-input__inner{
		padding-left: 80px;
	}
	
	.getcode ::v-deep .el-input__inner{
		padding-right: 80px;
	}
	.getcode_num{
		width: 80px;
		text-align: center;
		cursor: pointer;
		color: #b6babf;
		font-size: 14px;
	}
	
	.imgCQ{
		position: absolute;
		top: 0;
		right: 0;
		width: 101px;
		height: 101px;
		display: block;
		background: url(./login/img/account_2.png) no-repeat 50%;
		background-size: 90px;
		cursor: pointer;
		z-index: 9;
	}
	.imgCQ:hover{
		width: 101px;
		height: 101px;
		background-image: url(./login/img/account_hover2.png)!important;
		transition: all .4s ease;
		background-size: 90px;
	}
	.imgTQ{
		position: absolute;
		right: 0;
		top: 0;
		width: 90px;
		height: 90px;
		border-radius: 0 10px 0 0;
		border-color: #f2f7ff #f2f7ff #fff #fff;
		border-style: solid;
		border-width: 50px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	.qr_box{
		width: 240px;
		height: 284px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		border: 1px solid #f0f2f5;
		border-radius: 6px;
		padding: 12px;
		margin:35px auto 28px auto;
		position: relative;
		-webkit-transition: margin .5s ease;
		transition: margin .5s ease;
		&_img{
			width: 216px;
			height: 216px;
			border-radius:6px;
			background-color: #8a9099;
			
		}
		&_flex{
			display: flex;
			align-items: center;
			justify-content: center;
			color: #8a9099;
			font-size: 14px;
			margin-top: 12px;
			img{
				width: 20px;
				height: 20px;
				margin-right: 4px;
			}
		}
	}
</style>